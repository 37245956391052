import { IEnvironment } from '@env/environment.model';

export const environment: IEnvironment = {
  production: true,
  config: {
    logs: {
      // required config
      collector:
        'ZaVnC4dhaV1hrqX4la4-k57v3_L4lZ8f5eXvAKmE-PVxp5p9_of56VfijGTTTZzp0TgVtfBGuHFYPtqVuGJNOorzGIR67Kl_XK_P4ROrlnvd1miW8-dvEw==',
      // optional config
      endpoint: 'https://collectors.au.sumologic.com/receiver/v1/http/',
      level: 'info',
    },
    layout: {
      renderer: 'https://leapcalc-renderer-uk-test.leap.services',
    },
    cache: {
      roleARN: 'arn:aws:iam::438555434192:role/redis-shared-parameterstore-eu-west-1',
      sessionName: 'leap365-sirius',
      parameterStoreKey: 'redis-shared-connection',
      awsRegion: 'eu-west-1',
    },
    brand: {
      analytics: 'GTM-MD8QMN6',
      id: 'test.leap365.co.uk',
      name: 'Leap',
      leapcalc: 'leap',
      env: 'test',
      region: 'uk',
      helpUrl: '//community.leap.co.uk/s',
      termsUrl: 'https://leap.co.uk/terms',
      privacyUrl: 'https://leap.co.uk/privacy',
      brandUrl: 'test.leap365.co.uk',
      bylawyersUrl: 'https://www.leap.co.uk/integrations/bylawyers',
      copyrightName: 'LEAP Software Developments Pty. Limited',
    },
    precedent: {
      letterContainerId: '204000',
    },
    support: {
      communityUrl: 'https://leap--community.sandbox.my.site.com/ukhelpcentre/s',
      liveChatUrl: 'https://secure.livechatinc.com/licence/4997331/v2/open_chat.cgi',
      feedbackUrl: 'https://uxform.typeform.com/to',
      feedbackCode: 'XbNsAv',
    },
    oauth: {
      scopes: '*',
      endpoint: 'https://login.test.leap365.co.uk',
      user_info_uri: '/api/userinfo',
      provider_id: 'LEO',
      client_id: 'QZACTPEA4IIB4NFE',
      uniqueSessionActive: true,
      autoLogoutActive: false,
      userPresence: true,
      backendClientId: 'SNAXWYYI1RRKYGT2',
    },
    automation: {
      uriSchema: 'leapuktest',
    },
    keys: {
      pnSubscribe: 'sub-a456f002-0095-11e2-9638-9581afc33ebf',
    },
    apps: {
      glLink: 'https://test-gllinkapp.leapaws.com',
      marketPlace: 'https://test.marketplace.leap.build/apps',
      lawconnect: 'https://lawconnect.test.leap365.co.uk',
      appIds: {
        infotrack: [],
        timesheet: ['e6ac2501-79b8-4676-b163-ca5d62b9b60c', 'df673af0-416f-46c7-a900-90f24b249abb'],
      },
      appFunctions: {
        timesheet: ['6c06130e-9233-40de-bef8-4f27a52efaa0'],
      },
    },
    endpoint: {
      account: 'https://account2-api-uk-test.leap.services',
      accounting: 'https://api-offexaccounting-test.leapaws.co.uk',
      accountingv2: 'https://acc-api-test.leapaws.co.uk',
      adminLawConnect: 'https://admin.test.lawconnect.co.uk',
      appConsole: 'https://api.test.console.leap.build',
      automation: 'https://office-automation-api-test.leapaws.co.uk',
      bankrec: 'https://bankrec.test.leap365.co.uk',
      bylawyers: 'https://test.bylawyers.co.uk',
      calendar: 'https://superdiary-api-test.leapaws.co.uk',
      cdn: 'https://cdn.leap.co.uk',
      docs: 'https://api-docs-test.leapaws.co.uk',
      docsPublic: 'https://api-docs-public-test.leapaws.co.uk',
      docBuilder: 'https://doc-builder-test.leapaws.co.uk',
      infotrack: 'https://stagesearch.infotrack.co.uk',
      trisearch: 'https://stagesearch.infotrack.co.uk',
      leaponline: 'https://updates.test.leapaws.co.uk/leap-online/public/LEAPWebInstaller.exe',
      myobweb: '',
      pdfservice: 'https://pdfservice-test.leapaws.co.uk',
      quickbook: '',
      reporting: 'https://reporting-test.leapaws.co.uk',
      reportingApi: 'https://reporting-api-test.leapaws.co.uk',
      schema: 'https://schema-api-uk-test.leap.services',
      wopi: 'https://office-wopi-api-test.leapaws.co.uk',
      xero: 'https://xeroweb-test.leapaws.co.uk',
      auth: 'https://login.test.leap365.co.uk',
      globalAuth: 'https://auth-test.leap.services',
      options: 'https://acc-options-api-test.leapaws.co.uk',
      options_app: 'https://settings.test.leap365.co.uk',
      leapadin: 'https://leapaddins-api-test.leapaws.co.uk',
      listaddin: 'https://updates-leapaws-co-uk.s3.amazonaws.com/1/test/x64/matteraddin/api-listV3.json',
      notifications: 'https://notificationsapi-test.leapaws.co.uk',
      lcintegrator: 'https://lcintegrator-test.leapaws.co.uk',
      phoenix: 'https://guides.test.bylawyers.co.uk',
      msteams: 'https://teams-test.leapaws.co.uk',
      content: 'https://api-contentgateway-test.leapaws.co.uk',
      docsV2: 'https://api-docs-core.test.leapaws.co.uk',
      leapDesign: 'https://leapdesign.leapaws.co.uk/uk/test',
    },
    aws: {
      reportingBucket: 'sirius-transactions-test-leapaws-co-uk',
      region: 'eu-west-1',
      paramStorePath: '/officecloud/leap365/',
      accountId: '064865921863',
    },
    ksd: {
      enabled: true,
      configurations: [
        {
          protocol: 'https',
          method: '*',
          domain: `api-docs-test.leapaws.co.uk`,
          path: '/api/*',
        },
      ],
    },
    esignature: {
      enabled: true,
    },
    invoiceSharing: {
      enabled: false,
    },
    featureFlag: {
      client_id: '6655571aaba81f105a9219d3',
    },
    auth0: {
      domain: 'leapauthtest.au.auth0.com',
      clientId: 'FM6QHtgu9Ftwrn5HBs0XKjADVMX74IzV',
    },
  },
};
